import React, { Component } from 'react'
import { Link } from 'gatsby'
import Sticky from 'react-sticky-el'
import { FormattedMessage } from 'react-intl'
import SingleReference from './SingleReference'
import SingleFrontpageReference from './SingleFrontpageReference'

class References extends Component {
  render() {
    const { data, frontpage } = this.props
    const layout = data
    let referencesMarkup = null
    let classes = ''

    if (frontpage) {
      classes = 'section flex constrained frontpage-masterpieces'
      const firstReferencesMarkup = layout.references
        .filter((i, index) => index < 1)
        .map((reference, i) => (
          <SingleFrontpageReference
            data={reference}
            index={i}
            length={layout.references.length}
            key={`${i}-single-frontpage-reference`}
          />
        ))

      const secondAndThirdReferenceMarkup = layout.references
        .filter((i, index) => index >= 1 && index < 3)
        .map((reference, i) => (
          <SingleFrontpageReference
            data={reference}
            index={i}
            length={layout.references.length}
            key={`${i}-single-frontpage-reference`}
          />
        ))

      let desktopMoreWorkLink = ''
      let mobileMoreWorkLink = ''

      if (
        layout.references_page_link !== '' &&
        layout.references_page_link !== 'tyhja'
      ) {
        desktopMoreWorkLink = (
          <div className="link-to-work desktop">
            <Link to={layout.references_page_link}>
              <FormattedMessage id="more_of_our_work" />
              <span className="icon-arrow-right" />
            </Link>
          </div>
        )

        mobileMoreWorkLink = (
          <div className="link-to-work mobile">
            <Link to={layout.references_page_link}>
              <FormattedMessage id="more_of_our_work" />
              <span className="icon-arrow-right" />
            </Link>
          </div>
        )
      }

      referencesMarkup = (
        <>
          <div className="horizontal-flex">
            <div className="size-- left-side frontpage-reference-wrapper sticky-container frontpage-sticky">
              <div className="sticky-wrapper">
                <Sticky
                  boundaryElement=".frontpage-sticky"
                  hideOnBoundaryHit={false}
                  style={{ position: 'relative' }}
                >
                  <div className="contents">{firstReferencesMarkup}</div>
                </Sticky>
              </div>
            </div>
            <div className="size-- right-side frontpage-reference-wrapper">
              {secondAndThirdReferenceMarkup}
              {mobileMoreWorkLink}
            </div>
          </div>
          {desktopMoreWorkLink}
        </>
      )
    } else {
      classes = 'section constrained masterpieces flex'
      referencesMarkup = layout.references.map((reference, i) => (
        <SingleReference
          data={reference}
          index={i}
          length={layout.references.length}
          key={`${i}-single-reference`}
        />
      ))
    }

    return <section className={classes}>{referencesMarkup}</section>
  }
}

export default References
