import React, { Component } from 'react'
import Img from 'gatsby-image'
import { Parallax } from 'react-scroll-parallax'
import Sticky from 'react-sticky-el'

class TwoImages extends Component {
  render() {
    const { data } = this.props
    const layout = data

    // console.log('two images: ', layout)
    const firstImg = layout.first_image.localFile.childImageSharp.fluid
    const secondImg = layout.second_image.localFile.childImageSharp.fluid

    let firstImageClasses = 'size--half'
    let secondImageClasses = 'size--half'

    if (layout.image_sizes === 'left_small') {
      firstImageClasses = 'size--small'
      secondImageClasses = 'size--large'
    }

    if (layout.image_sizes === 'right_small') {
      firstImageClasses = 'size--large'
      secondImageClasses = 'size--small'
    }

    firstImageClasses += ` sticky-container sticky-container-1`
    secondImageClasses += ` sticky-container sticky-container-2`

    return (
      <section className="section flex constrained images">
        <div className={firstImageClasses}>
          <div className="sticky-wrapper">
            <Sticky
              boundaryElement=".sticky-container-1"
              hideOnBoundaryHit={false}
              style={{ position: 'relative' }}
            >
              <div className="parallax-wrapper">
                <Parallax className="parallax" y={[-10, 10]} tagOuter="figure">
                  {/* <img src={firstImg.src} /> */}
                  <Img
                    src={firstImg.src}
                    fluid={firstImg}
                    loading="eager"
                    fadeIn={false}
                  />
                </Parallax>
              </div>
            </Sticky>
          </div>
        </div>
        <div className={secondImageClasses}>
          <div className="sticky-wrapper">
            <Sticky
              boundaryElement=".sticky-container-2"
              hideOnBoundaryHit={false}
              style={{ position: 'relative' }}
            >
              <div className="parallax-wrapper">
                <Parallax className="parallax" y={[-10, 10]} tagOuter="figure">
                  {/* <image src={secondImg.src} /> */}
                  <Img
                    src={secondImg.src}
                    fluid={secondImg}
                    loading="eager"
                    fadeIn={false}
                  />
                </Parallax>
              </div>
            </Sticky>
          </div>
        </div>
      </section>
    )
  }
}

export default TwoImages
