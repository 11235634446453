import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'

class Wysiwyg extends Component {
  render() {
    const { data } = this.props
    const layout = data

    return (
      <section className="section narrow wysiwyg">
        {ReactHtmlParser(layout.wysiwyg)}
      </section>
    )
  }
}

export default Wysiwyg
