import React from 'react'
import ReactHtmlParser from 'react-html-parser'

export default function Author({ data }) {
  return (
    <section className="section narrow wysiwyg author">
      {ReactHtmlParser(data.author_name)}
    </section>
  )
}
