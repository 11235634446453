import React, { Component } from 'react'

class People extends Component {
  render() {
    const { data } = this.props
    const layout = data

    const peopleMarkup = layout.peeps.map((peep, i) => (
      <li key={`${i}-single-peep`}>
        <strong>{peep.title}</strong>
        <br />
        {peep.name}
        <br />
        <a href={`tel:${peep.tel}`}>{peep.tel}</a>
        <br />
        <a href={`mailto:${peep.email}`}>{peep.email}</a>
      </li>
    ))

    return (
      <section className="section narrow people">
        <ul className="peeps">{peopleMarkup}</ul>
      </section>
    )
  }
}

export default People
