import React, { Component } from 'react'
import ResponsiveEmbed from 'react-responsive-embed'

class VideoEmbed extends Component {
  render() {
    const { data } = this.props
    const layout = data
    const videoURL = layout.url
    const classes = 'size--medium'

    return (
      <section className="section flex constrained images">
        <div className={classes}>
          <ResponsiveEmbed src={videoURL} allowFullScreen />
        </div>
      </section>
    )
  }
}

export default VideoEmbed
