import React, { Component } from 'react'

class FormHelper extends Component {
  state = {
    Form: null,
  }

  componentWillMount() {
    import('./Form').then(Form => {
      this.setState({ Form: Form.default })
    })
  }

  render() {
    const { data, siteUrl } = this.props
    let { Form } = this.state
    if (!Form) {
      return <div />
    } else {
      return <Form data={data} siteUrl={siteUrl} />
    }
  }
}

export default FormHelper
