import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { StaticQuery, Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Parallax } from 'react-scroll-parallax'
import { FormattedMessage } from 'react-intl'

import SearchIcon from '../assets/img/search-icon.svg'

/**********************************************
 *
 * It is also possible to keep sending new queries when
 * loading more by adding the the limit filter in the query
 *
 ********************************************* */

// helper function --> creating the images
function Image({ img }) {
  let classes = 'size--medium'

  return (
    <div className={`${classes} blog-feed__image`}>
      <Img
        src={img.src}
        fluid={{ ...img, aspectRatio: 16 / 9 }}
        loading="eager"
        fadeIn={false}
      />
    </div>
  )
}

// helper function --> creating the content
function Content({ acf, link, date }) {
  const content_exists = typeof acf !== 'undefined' ? true : false

  return (
    <Parallax y={[20, -40]} tagInner="figure">
      <div className="blog-feed__content">
        {/* <span className="date">{date}</span> */}
        <h2>{ReactHtmlParser(acf.title)}</h2>
        <div className="lead">{ReactHtmlParser(acf.listing_lead_text)}</div>
        <Link to={link}>
          <FormattedMessage id="read_the_whole_story" />
        </Link>
      </div>
    </Parallax>
  )
}

class BlogPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loads: 5,
      totalBlogs: 0,
      allPublishedPosts: null,
      allQueriedPosts: null,
      filteredPosts: null,
      searchInput: '',
    }
  }

  componentWillMount = () => {
    const posts = this.props.posts.allWordpressPost.edges

    //console.log('all posts: ', posts)

    // check if published then language match
    const allPublishedPosts = posts.filter(post => {
      const { status, lang } = post.node
      if (lang === this.props.lang && status === 'publish') return post
    })

    let categories = []

    posts.map(({ node }) => {
      node.categories.map((element, index) => {
        if (!categories.includes(element.name)) {
          categories.push(element.name)
        }
      })
    })

    //console.log(allPublishedPosts)

    this.setState({
      allQueriedPosts: posts,
      allPublishedPosts,
      totalBlogs: allPublishedPosts.length,
      tags: categories,
    })
  }

  // enter search input
  updateSearchInput = e => {
    e.preventDefault
    this.setState({ searchInput: e.currentTarget.value })
  }

  // filter search input on enter // submit
  filterTextSearchInput = e => {
    if (e.keyCode === 13) this.searchByTextFilter()
  }

  // remove the search input default from input
  removeSearchDefault = () => {
    if (this.state.searchInput === 'Search') this.setState({ searchInput: '' })
  }

  // set to default if there is no search input entered
  // checkInputValue = e => {
  //   e.preventDefault
  //   if (this.state.searchInput === '') this.setState({ searchInput: 'Search' })
  // }

  // get blogs by text filter
  searchByTextFilter = () => {
    const { searchInput, allPublishedPosts } = this.state
    const search = searchInput.toLowerCase()

    // split string into array
    const searchInputsinArray = search.split(/\s+/)
    // remove white spaces inputs from array
    const searchInputs = searchInputsinArray.filter(str => /\S/.test(str))

    let filterResult = []

    if (search.length > 0) {
      allPublishedPosts.map(({ node }) => {
        return searchInputs.map(word => {
          let matchFound = false

          // Check title
          const titleData = node.acf.title.split(/\s+/)

          titleData.map(contentWord => {
            const contentWordLowerCase = contentWord.toLowerCase()
            if (contentWordLowerCase.indexOf(word) !== -1 && !matchFound) {
              matchFound = true

              // push first match if there aren't any yet
              if (filterResult.length === 0)
                return filterResult.push({ node: node })

              // filter if blog has already been added with a previous match
              const found = filterResult.find(results => {
                return results.node.id === node.id
              })

              // add blog if it doesn't exist in the filter yet
              if (!found) return filterResult.push({ node: node })
            }
          })

          // Check lead text

          const leadTextData = node.acf.listing_lead_text.split(/\s+/)

          leadTextData.map(contentWord => {
            const contentWordLowerCase = contentWord.toLowerCase()
            if (contentWordLowerCase.indexOf(word) !== -1 && !matchFound) {
              matchFound = true

              // push first match if there aren't any yet
              if (filterResult.length === 0)
                return filterResult.push({ node: node })

              // filter if blog has already been added with a previous match
              const found = filterResult.find(results => {
                return results.node.id === node.id
              })

              // add blog if it doesn't exist in the filter yet
              if (!found) return filterResult.push({ node: node })
            }
          })

          // Check flexible content
          if (
            typeof node.acf.flexible_content_post !== 'undefined' &&
            node.acf.flexible_content_post !== null
          ) {
            node.acf.flexible_content_post.map(flexibleContentItem => {
              if (flexibleContentItem.__typename === 'WordPressAcf_wysiwyg') {
                const contentToLowerCase = flexibleContentItem.wysiwyg.toLowerCase()
                let div = document.createElement('div')

                // set lower case content in div, in order to remove html tags
                div.innerHTML = contentToLowerCase
                const content = div.textContent

                // split search content
                const data = content.split(/\s+/)

                // search through the content of each blog
                data.map(contentWord => {
                  if (contentWord.indexOf(word) !== -1 && !matchFound) {
                    matchFound = true

                    // push first match if there aren't any yet
                    if (filterResult.length === 0)
                      return filterResult.push({ node: node })

                    // filter if blog has already been added with a previous match
                    const found = filterResult.find(results => {
                      return results.node.id === node.id
                    })

                    // add blog if it doesn't exist in the filter yet
                    if (!found) return filterResult.push({ node: node })
                  }
                })
              }
            })
          }
        })
      })
      // set new blog results
      this.setState({
        filteredPosts: filterResult,
        totalBlogs: filterResult.length,
      })
    } else {
      // use all results -- default
      this.setState({ filteredPosts: null })
    }
  }

  // filter blogs by tag
  searchByTag = searchTag => {
    let newResult = []

    this.state.allPublishedPosts.map(({ node }) => {
      node.categories.filter(category => {
        if (category.name === searchTag) newResult.push({ node: node })
      })
    })

    // set tag results
    this.setState({ filteredPosts: newResult, totalBlogs: newResult.length })
  }

  // load more articles if there are any
  loadMoreArticles = () => this.setState({ loads: this.state.loads + 5 })

  // generate / build all blogs
  generatePosts = () => {
    const { allPublishedPosts, filteredPosts, loads } = this.state
    let allPosts = []

    if (filteredPosts === null) allPosts = allPublishedPosts
    if (filteredPosts !== null) allPosts = filteredPosts

    //console.log(allPublishedPosts)

    // build all posts/articles
    return allPosts.map(({ node }, i) => {
      if (i <= loads + 1) {
        const image = node.acf.post_image.localFile.childImageSharp.fluid
        return (
          <section key={i} className="section constrained blog-feed">
            <Content acf={node.acf} link={node.path} date={node.date} />
            <Image img={image} />
          </section>
        )
      }
    })
  }

  render() {
    const { totalBlogs, loads, searchInput, tags } = this.state
    const { data } = this.props
    let tagOptions

    // style search icon -- scss doesn't work for some reason
    const SearchIconStyle = {
      position: 'absolute',
      top: '50%',
      transform: `translateY(${-50}%)`,
    }

    // generate / build blogs
    const listOfBlogs = this.generatePosts()

    // display load more articles/blogs button
    const showMore =
      loads <= totalBlogs ? (
        <section className="section narrow blog__load">
          <label onClick={this.loadMoreArticles} className="blog__load-loader">
            <FormattedMessage id="load_more_articles" />
          </label>
        </section>
      ) : null

    // generate search tags
    if (tags) {
      tagOptions = tags
        .filter(tag => tag !== 'Uncategorized')
        .map((tag, i) => {
          return (
            <label
              key={i}
              onClick={() => this.searchByTag(tag)}
              name={tag}
            >{`# ${tag}`}</label>
          )
        })
    }

    return (
      <>
        <section className="section narrow blog__search">
          <div style={{ position: 'relative' }}>
            <img style={SearchIconStyle} src={SearchIcon} />
            <FormattedMessage id="search">
              {placeholder => (
                <input
                  className="blog__search-input"
                  value={searchInput}
                  onFocus={this.removeSearchDefault}
                  onBlur={this.checkInputValue}
                  onChange={e => this.updateSearchInput(e)}
                  onKeyDown={this.filterTextSearchInput}
                  placeholder={placeholder}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="blog__search-tags">{tagOptions}</div>
        </section>
        {listOfBlogs}
        {showMore}
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query GetAllPosts {
        allWordpressPost(sort: { fields: date, order: DESC }) {
          edges {
            node {
              id
              wordpress_id
              status
              link
              date(formatString: "DD.MM.YYYY")
              excerpt
              path
              lang
              translations {
                en
                fi
              }
              categories {
                name
              }
              acf {
                title
                post_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2560, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
                listing_lead_text
                flexible_content_post {
                  __typename
                  ... on WordPressAcf_wysiwyg {
                    wysiwyg
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={posts => {
      return <BlogPage posts={posts} {...props} />
    }}
  />
)
