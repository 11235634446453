import React from 'react'
import Img from 'gatsby-image'

export default function BackgroundPageImage({ image, position }) {
  const data = image.localFile.childImageSharp.fluid
  const defaultStyle = {
    position: 'fixed',
    height: '466px',
    width: '466px',
    zIndex: '-1',
  }

  let stylePosition

  switch (position) {
    case 'left':
      stylePosition = {
        top: '25vh',
        left: '-220px',
      }
      break

    case 'right':
      stylePosition = {
        top: '-15vh',
        right: '-220px',
      }
      break

    case 'top':
      stylePosition = {
        top: '-200px',
        right: '20vw',
      }
      break

    case 'bottom':
      stylePosition = {
        bottom: '-150px',
        right: '10vw',
      }
      break

    default:
      break
  }

  return (
    // <div style={Object.assign(defaultStyle, stylePosition)}>
    // <div style={{ ...defaultStyle, ...stylePosition }}>
    //   {/* <img src={data.src} /> */}
    //   <Img src={data.source} fluid={data} loading="eager" fadeIn={false} />
    // </div>
    ''
  )
}
