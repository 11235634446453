import React from 'react'
import { Link } from 'gatsby'

const ColumnTitle = props => {
  const { data } = props

  if (data.column_title !== 'tyhja') {
    if (data.column_title_link !== 'tyhja') {
      if (data.column_title_link.includes('http')) {
        return (
          <h3>
            <a href={data.column_title_link}>{data.column_title}</a>
          </h3>
        )
      }
      return (
        <h3>
          <Link to={data.column_title_link}>{data.column_title}</Link>
        </h3>
      )
    }
    return <h3>{data.column_title}</h3>
  }
  return null
}

export default ColumnTitle
