import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SingleImage from './SingleImage'
import Columns from './Columns'
import TwoImages from './TwoImages'
import Author from './Author'
import Wysiwyg from './Wysiwyg'
import References from './References'
import SingleVideo from './SingleVideo'
import VideoAndImage from './VideoAndImage'
import People from './People'
import VideoEmbed from './VideoEmbed'
import FormHelper from './FormHelper'
import BlogPage from './BlogPage'
import RekryPage from './Rekry'
import RekryPostHeader from './RekryPostHeader'
import RekryPostContent from './RekryPostContent'
import RekryPostContact from './RekryPostContact'
import BackgroundPageImage from './BackgroundPageImage'
import Quote from './Quote'
import WriterBlock from './WriterBlock'

const FlexibleContent = props => {
  const { data, lang, frontpage, intl } = props
  const acfFlexibleContent = data
  let flexibleContents = null
  const lastType = ''

  if (acfFlexibleContent) {
    flexibleContents = acfFlexibleContent.map((layout, i) => {
      if (layout.__typename === 'WordPressAcf_author') {
        return <Author data={layout} key={`${i}-author`} />
      }
      if (layout.__typename === 'WordPressAcf_wysiwyg') {
        return <Wysiwyg data={layout} key={`${i}-wysiwyg`} />
      }

      if (layout.__typename === 'WordPressAcf_two_images') {
        return <TwoImages data={layout} key={`${i}-two-images`} />
      }

      if (layout.__typename === `WordPressAcf_image`) {
        return <SingleImage data={layout} key={`${i}-single-image`} />
      }

      if (layout.__typename === `WordPressAcf_text_in_columns`) {
        return <Columns data={layout} key={`${i}-text-in-columns`} />
      }

      if (layout.__typename === `WordPressAcf_links_to_references`) {
        return (
          <References
            data={layout}
            key={`${i}-references`}
            frontpage={frontpage}
          />
        )
      }

      if (layout.__typename === `WordPressAcf_video`) {
        return <SingleVideo data={layout} key={`${i}-video`} />
      }

      if (layout.__typename === `WordPressAcf_video_and_image`) {
        return <VideoAndImage data={layout} key={`${i}-video-and-image`} />
      }

      if (layout.__typename === `WordPressAcf_staff`) {
        return <People data={layout} key={`${i}-people`} />
      }

      if (layout.__typename === 'WordPressAcf_video_embed') {
        return <VideoEmbed data={layout} key={`${i}-video-embed`} />
      }

      if (layout.__typename === 'WordPressAcf_form') {
        // Get siteUrl for Form component
        const siteMeta = useStaticQuery(graphql`
          query SiteMetaQuery {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `)
        return (
          <FormHelper
            data={layout}
            siteUrl={siteMeta.site.siteMetadata.siteUrl}
            key={`${i}-form`}
          />
        )
      }

      // our blog page -- daniel
      if (layout.__typename === 'WordPressAcf_blog') {
        return (
          <BlogPage
            data={layout}
            key={`${i}-blog-page`}
            lang={lang}
            intl={intl}
          />
        )
      }

      // rekry page-- daniel
      if (layout.__typename === 'WordPressAcf_open_job_positions') {
        return <RekryPage data={layout} key={`${i}-rekry-page`} />
      }

      if (layout.__typename === 'WordPressAcf_quote') {
        return <Quote data={layout} key={`${i}-quote`} />
      }

      if (layout.__typename === 'WordPressAcf_writer_block') {
        return <WriterBlock data={layout} key={`${i}-writer-block`} />
      }

      // // rekry page -- daniel
      // if (layout.__typename === 'WordPressAcf_open_job_positions') {
      //   return <RekryPage data={layout} key={`${i}-rekry-page`} />
      // }

      // // rekry post -- daniel
      // if (layout.__typename === 'WordPressAcf_header') {
      //   return <RekryPostHeader data={layout} key={`${i}-rekry-page`} />
      // }

      // // rekry post -- daniel
      // if (layout.__typename === 'WordPressAcf_content') {
      //   return <RekryPostContent data={layout} key={`${i}-rekry-page`} />
      // }

      // // rekry post -- daniel
      // if (layout.__typename === 'WordPressAcf_contact') {
      //   return <RekryPostContact data={layout} key={`${i}-rekry-page`} />
      // }

      // background image that looks like firework + on the background
      // if (layout.__typename === 'WordPressAcf_contact') {
      //   <BackgroundPageImage />
      // }

      return null
    })
  }

  return <>{flexibleContents}</>
}

export default FlexibleContent
