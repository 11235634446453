import React, { Component } from 'react'
import Img from 'gatsby-image'
import { Parallax } from 'react-scroll-parallax'

class SingleImage extends Component {
  render() {
    const { data } = this.props
    const layout = data
    const img = layout.image.localFile.childImageSharp.fluid
    const customClass = layout.class_name
    let classes = ''

    switch (layout.image_size) {
      case 'full':
        classes = 'size--full'
        break

      case 'small':
        classes = 'size--square'
        break

      default:
        classes = 'size--medium'
        break
    }

    console.log('images: ', img.src)

    return (
      <section className={`section flex constrained images ${customClass}`}>
        <div className={classes}>
          <Parallax className="parallax" y={[-10, 10]} tagOuter="figure">
            <Img src={img.src} fluid={img} loading="eager" fadeIn={false} />
            {/* <img src={img.src} /> */}
          </Parallax>
        </div>
      </section>
    )
  }
}

export default SingleImage
