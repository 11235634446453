import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'

class Quote extends Component {
  render() {
    const { data } = this.props
    const layout = data

    return (
      <section className="section narrow quote">
        <div className="quote-text">{layout.quote}</div>
        {layout.quoted_person ? (
          <div className="quoted-person">{`- ${layout.quoted_person}`}</div>
        ) : (
          ''
        )}
      </section>
    )
  }
}

export default Quote
