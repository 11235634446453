import React, { Component } from 'react'
import { Parallax } from 'react-scroll-parallax'

class SingleVideo extends Component {
  render() {
    const { data } = this.props
    const layout = data
    const videoURL = layout.video_file.localFile.url
    const classes = layout.full_width_video ? 'size--full' : 'size--medium'

    return (
      <section className="section flex constrained images">
        <div className={classes}>
          <Parallax className="parallax" y={[-10, 10]} tagInner="figure">
            <video autoPlay playsInline muted loop className={classes}>
              <source src={videoURL} type="video/mp4" />
            </video>
          </Parallax>
        </div>
      </section>
    )
  }
}

export default SingleVideo
