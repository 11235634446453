import React, { Component } from 'react'
import SingleColumn from './SingleColumn'

class Columns extends Component {
  render() {
    const { data } = this.props
    const layout = data

    const columnsMarkup = layout.columns.map((column, i) => (
      <SingleColumn data={column} key={`${i}-single-column`} />
    ))

    return (
      <section className={`section narrow ${layout.class_name}`}>
        <ul className="column-list">{columnsMarkup}</ul>
      </section>
    )
  }
}

export default Columns
