import React, { Component } from 'react'
import Img from 'gatsby-image'
import ReactHtmlParser from 'react-html-parser'

class WriterBlock extends Component {
  render() {
    const { data } = this.props
    const layout = data

    return (
      <section className="section narrow writer-block">
        <div className="image">
          <Img
            src={layout.writer.image.localFile.childImageSharp.fluid.src}
            fluid={layout.writer.image.localFile.childImageSharp.fluid}
            loading="eager"
            fadeIn={false}
          />
        </div>
        <div className="texts">
          <div className="name">
            {`${layout.writer.first_name} ${layout.writer.last_name}`}
          </div>
          <div className="job-title">{layout.writer.job_title}</div>
          <div className="email">{layout.writer.email}</div>
          {layout.writer.self_congratulatory ? (
            <div className="self-congratulatory">
              {layout.writer.self_congratulatory}
            </div>
          ) : (
            ''
          )}
        </div>
      </section>
    )
  }
}

export default WriterBlock
