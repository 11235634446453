import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Link } from 'gatsby'
import { useIntl } from 'react-intl'

export default function Rekry({ data }) {
  if (typeof data !== 'undefined') {
    const intl = useIntl()
    const lang = intl.locale

    // const prefix = lang !== 'en' ? `/${lang}` : ''

    const openPositions = data.positions.map(position => (
      <li className="rekry position">
        <Link to={`${position.link}`} className="arrow-link normal-size">
          {position.position}
          <span className="icon-arrow-right" />
        </Link>
      </li>
    ))

    return (
      <section className="section narrow rekry">
        <div className="rekry title">
          <h2>{data.title}</h2>
        </div>
        <div className="rekry list">
          <ul className="rekry positions">{openPositions}</ul>
        </div>
        <div>{ReactHtmlParser(data.wysiwyg)}</div>
      </section>
    )
  }
  return null
}
