import React, { Component } from 'react'
import { Link } from 'gatsby'
import ColumnTitle from './ColumnTitle'

class SingleColumn extends Component {
  render() {
    const { data } = this.props

    const linesMarkup = data.lines.map((line, i) => (
      <li key={`${i}-single-column`}>{line.text}</li>
    ))

    return (
      <li>
        <ColumnTitle data={data} />
        <ul>{linesMarkup}</ul>
      </li>
    )
  }
}

export default SingleColumn
