import React, { Component } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { Parallax } from 'react-scroll-parallax'
import Sticky from 'react-sticky-el'

class SingleReference extends Component {
  render() {
    const { data, index, length } = this.props
    let SingleReferenceMarkup = null
    let classes = ''
    let marketingText = ''
    const img = data.image.localFile.childImageSharp.fluid
    const parallaxY = [-10, 10]

    // if (index % 4 === 0) {
    //   classes = 'size--small'
    // } else if (index % 4 === 1) {
    //   classes = 'size--large'
    // } else if (index % 4 === 2) {
    //   classes = 'size--large'
    // } else if (index % 4 === 3) {
    //   classes = 'size--small'
    // }

    // // Viimeinen jos pariton -> size-full
    // if (index + 1 === length && index % 2 === 0) {
    //   classes = 'size--medium'
    // }
    classes = 'size--half'

    // } else if (index % 6 === 4) {
    //   classes = 'size--large'
    // } else if (index % 6 === 5) {
    //   classes = 'size--small'
    // }

    if (data.marketing_text) {
      marketingText = (
        <span className="marketing-text">{data.marketing_text}</span>
      )
    }

    if (data.link !== 'tyhja') {
      classes += ' reference-link'
      if (data.link.includes('http')) {
        SingleReferenceMarkup = (
          <a href={data.link} className={classes}>
            <Parallax className="parallax" y={[-15, 15]} tagInner="figure">
              {/* <img src={img.src} /> */}
              <Img
                src={img.src}
                fluid={{ ...img, aspectRatio: 16 / 9 }}
                loading="eager"
                fadeIn={false}
              />
            </Parallax>
            <div className="text-wrapper">
              <div className="moving-text-wrapper">
                <span className="reference-name">{data.link_text}</span>
                {marketingText}
              </div>
              <span className="icon-arrow-right" />
            </div>
          </a>
        )
      } else {
        classes += ` sticky-container sticky-container-${index}`
        SingleReferenceMarkup = (
          <div className={classes}>
            <div className="sticky-wrapper">
              {/* <Sticky
                boundaryElement={`.sticky-container-${index}`}
                hideOnBoundaryHit={false}
                style={{ position: 'relative' }}
              > */}
              <div className="parallax-wrapper">
                <Link to={data.link} className="reference-link">
                  <div className="span-positioner">
                    <Parallax
                      className="parallax"
                      y={parallaxY}
                      tagOuter="figure"
                    >
                      <div className="image-wrapper">
                        {/* <img src={img.src} /> */}
                        <Img
                          src={img.src}
                          fluid={{ ...img, aspectRatio: 16 / 9 }}
                          loading="eager"
                          fadeIn={false}
                        />
                      </div>
                    </Parallax>
                    <div className="text-wrapper">
                      <div className="moving-text-wrapper">
                        <span className="reference-name">{data.link_text}</span>
                        {marketingText}
                      </div>
                      <span className="icon-arrow-right" />
                    </div>
                  </div>
                </Link>
              </div>
              {/* </Sticky> */}
            </div>
          </div>
        )
      }
    } else {
      SingleReferenceMarkup = (
        <span className={classes}>
          <Parallax className="parallax" y={[-15, 15]} tagInner="figure">
            {/* <img src={img.src} /> */}
            <Img
              src={img.src}
              fluid={{ ...img, aspectRatio: 16 / 9 }}
              loading="eager"
              fadeIn={false}
            />
          </Parallax>
        </span>
      )
    }

    return <>{SingleReferenceMarkup}</>
  }
}

export default SingleReference
