import React, { Component } from 'react'
import Img from 'gatsby-image'
import { Parallax } from 'react-scroll-parallax'
import Sticky from 'react-sticky-el'

class VideoAndImage extends Component {
  render() {
    const { data } = this.props
    const layout = data
    const img = layout.image.localFile.childImageSharp.fluid
    const videoURL = layout.video.localFile.url

    let imageClasses = 'size--half'
    let videoClasses = 'size--half'
    let sectionClasses = 'section flex constrained images'

    if (layout.alignment === 'video_left_small') {
      imageClasses = 'size--large'
      videoClasses = 'size--small'
      sectionClasses = 'section flex constrained images reversed'
    }

    if (layout.alignment === 'video_left_large') {
      imageClasses = 'size--small'
      videoClasses = 'size--large'
      sectionClasses = 'section flex constrained images reversed'
    }

    if (layout.alignment === 'video_right_small') {
      imageClasses = 'size--large'
      videoClasses = 'size--small'
    }

    if (layout.alignment === 'video_right_large') {
      imageClasses = 'size--small'
      videoClasses = 'size--large'
    }

    imageClasses += ` sticky-container sticky-container-1`
    videoClasses += ` sticky-container sticky-container-2`

    return (
      <section className={sectionClasses}>
        <div className={imageClasses}>
          <div className="sticky-wrapper">
            <Sticky
              boundaryElement=".sticky-container-1"
              hideOnBoundaryHit={false}
              style={{ position: 'relative' }}
            >
              <div className="parallax-wrapper">
                <Parallax className="parallax" y={[-10, 10]} tagOuter="figure">
                  {/* <img  src={img.src} /> */}
                  <Img
                    src={img.src}
                    fluid={img}
                    loading="eager"
                    fadeIn={false}
                  />
                </Parallax>
              </div>
            </Sticky>
          </div>
        </div>
        <div className={videoClasses}>
          <div className="sticky-wrapper">
            <Sticky
              boundaryElement=".sticky-container-2"
              hideOnBoundaryHit={false}
              style={{ position: 'relative' }}
            >
              <div className="parallax-wrapper">
                <Parallax className="parallax" y={[-10, 10]} tagOuter="figure">
                  <video autoPlay playsInline muted loop>
                    <source src={videoURL} type="video/mp4" />
                  </video>
                </Parallax>
              </div>
            </Sticky>
          </div>
        </div>
      </section>
    )
  }
}

export default VideoAndImage
