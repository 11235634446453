import React, { Component } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { Parallax } from 'react-scroll-parallax'

class SingleFrontpageReference extends Component {
  render() {
    const { data, index, length } = this.props
    let SingleReferenceMarkup = null
    let classes = ''
    let marketingText = ''
    const imageMarkup = ''
    const img = data.image.localFile.childImageSharp.fluid

    if (data.marketing_text) {
      marketingText = (
        <span className="marketing-text">{data.marketing_text}</span>
      )
    }

    // } else if (index % 6 === 4) {
    //   classes = 'size--large'
    // } else if (index % 6 === 5) {
    //   classes = 'size--small'
    // }

    if (data.link !== 'tyhja') {
      classes += ' reference-link'
      if (data.link.includes('http')) {
        SingleReferenceMarkup = (
          <a href={data.link} className={classes}>
            <Parallax className="parallax" y={[-10, 10]} tagOuter="figure">
              <Img src={img.src} fluid={img} loading="eager" fadeIn={false} />
              {/* <img src={img.src} /> */}
            </Parallax>
            <div className="text-wrapper">
              <span className="reference-name">{data.link_text}</span>
              {marketingText}
              <span className="icon-arrow-right" />
            </div>
          </a>
        )
      } else {
        SingleReferenceMarkup = (
          <Link to={data.link} className={classes}>
            <Parallax className="parallax" y={[-10, 10]} tagOuter="figure">
              <Img
                src={img.src}
                fluid={{ ...img, aspectRatio: 1 }}
                loading="eager"
                fadeIn={false}
              />
              {/* <img src={img.src} /> */}
            </Parallax>
            <div className="text-wrapper">
              <span className="reference-name">{data.link_text}</span>
              {marketingText}
              <span className="icon-arrow-right" />
            </div>
          </Link>
        )
      }
    } else {
      SingleReferenceMarkup = (
        <span className={classes}>
          <Parallax className="parallax" y={[-10, 10]} tagOuter="figure">
            <Img src={img.src} fluid={img} loading="eager" fadeIn={false} />
            {/* <img src={img.src} /> */}
            {/* <Img src={img.src} fluid={img} loading={'eager'} fadeIn={false} critical /> */}
          </Parallax>
        </span>
      )
    }

    return <>{SingleReferenceMarkup}</>
  }
}

export default SingleFrontpageReference
